import { makeStyles } from '@material-ui/core';

import colors from '../../../theme/colors';

const useStyles = makeStyles((theme) => ({
    paper: {
        backgroundColor: colors.black,
        backgroundImage: 'url("/images/freeShippingPopup/background.png")',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        width: '600px',
        height: '400px',
        color: 'white',
        position: 'relative',
    },
    iconButton: {
        position: 'absolute',
        top: 10,
        right: 10,
        color: 'white',
    },
    dialogContent: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        height: '100%',
        overflowY: 'visible',
    },
    title: {
        fontWeight: 850,
        color: colors.yellow,
        lineHeight: '43px',
        fontSize: '32px',
        [theme.breakpoints.up('md')]: {
            fontSize: '36px',
        },
    },
    subtitle: {
        lineHeight: '24px',
        fontSize: '16px',
        [theme.breakpoints.up('md')]: {
            fontSize: '20px',
        },
    },
    button: {
        marginTop: '16px',
        fontWeight: 'bold',
        color: colors.yellow,
        height: '40px',
        width: '283px',
        '&:hover': {
            color: colors.black,
            backgroundColor: colors.yellow,
        },
    },
    paragrahp: {
        marginTop: '10px',
        fontSize: '10px',
        [theme.breakpoints.up('md')]: {
            fontSize: '11px',
        },
        lineHeight: '13px',
        fontWeight: 300,
    },
}));

export default useStyles;
