const INVOICE_TYPES = {
    finalConsumer: 'finalConsumer',
    invoiceA: 'invoiceA',
};

const IVA_CONDITIONS = [
    { name: 'IVA Responsable Inscripto', value: 'ivaResponsableInscripto' },
    { name: 'Monotributista Trabajador Independiente Promovido', value: 'monotributistaIndependientePromovido' },
    { name: 'Monotributista Social', value: 'monotributistaSocial' },
    { name: 'Responsable Monotributo', value: 'responsableMonotributo' },
];

const SHIPPING_TYPES = {
    homeDelivery: 'homeDelivery',
    pickupAtNearestAgency: 'pickupAtNearestAgency',
    pickupAtWarehouse: 'pickupAtWarehouse',
};

const DEFAULT_CENTER = { lat: -37.98, lng: -63.36 };

const EMAIL = 'hola@gamerfactory.com.ar';

const SHIPPING_ERROR_MESSAGE = 'Hubo un error al intentar obtener las agencias de envío. Por favor, intentá nuevamente.';

const TWENTY_FOUR_HOURS = 24;

const MILLISECONDS_PER_HOUR = 36e5;

export { EMAIL, DEFAULT_CENTER, INVOICE_TYPES, IVA_CONDITIONS, SHIPPING_TYPES, SHIPPING_ERROR_MESSAGE, TWENTY_FOUR_HOURS, MILLISECONDS_PER_HOUR };
