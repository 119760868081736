import { Button, Dialog, DialogContent, IconButton, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { MILLISECONDS_PER_HOUR, TWENTY_FOUR_HOURS } from '../../../common/constants';
import { useConfig } from '../../../contexts/Config/context';
import useStyles from './styles';

const FreeShippingPopup = () => {
    const {
        state: { FREE_SHIPPING },
    } = useConfig();
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();
    const classes = useStyles();

    const getHoursSinceLastShown = (lastShown: string): number => {
        const now = new Date();
        const lastShownDate = new Date(lastShown);
        return Math.abs(Number(now) - Number(lastShownDate)) / MILLISECONDS_PER_HOUR;
    };

    const savePopupShownTimestamp = (): void => {
        const now = new Date();
        localStorage.setItem('promoPopupLastShown', now.toISOString());
    };

    const shouldShowPopup = (lastShown: string | null): boolean => {
        if (!lastShown) return true;
        const hoursSinceLastShown = getHoursSinceLastShown(lastShown);
        return hoursSinceLastShown >= TWENTY_FOUR_HOURS;
    };
    useEffect(() => {
        if (FREE_SHIPPING) {
            const lastShown = localStorage.getItem('promoPopupLastShown');
            if (shouldShowPopup(lastShown)) {
                setOpen(true);
                savePopupShownTimestamp();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [FREE_SHIPPING, setOpen]);

    const handleClose = () => {
        setOpen(false);
    };

    const handleClick = () => {
        setOpen(false);
        navigate('/productos');
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            maxWidth="md"
            PaperProps={{
                className: classes.paper,
            }}
        >
            <IconButton onClick={handleClose} className={classes.iconButton}>
                <CloseIcon />
            </IconButton>

            <DialogContent className={classes.dialogContent}>
                <Typography variant="h2" className={classes.title}>
                    ENVÍO GRATIS
                </Typography>
                <Typography variant="subtitle1" className={classes.subtitle}>
                    A todo el país ✈️
                </Typography>
                <Button variant="outlined" className={classes.button} onClick={handleClick}>
                    COMPRÁ AHORA
                </Button>
                <Typography className={classes.paragrahp}>*Beneficio por tiempo limitado</Typography>
            </DialogContent>
        </Dialog>
    );
};

export default FreeShippingPopup;
